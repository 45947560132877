


































import Vue from "vue";

export default Vue.extend({
  name: "GoogleMap",
  data() {
    return {
      iframeSrc: ""
    };
  },
  mounted() {
    setTimeout( () => {
      this.iframeSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1988.3128663146947!2d-74.10019609790817!3d4.660642664375696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd880b0bf0b8abd8e!2sCentro%20de%20Convenciones%20Compensar!5e0!3m2!1sen!2sco!4v1625533602132!5m2!1sen!2sco"
    }, 300);
  }
});
